.nav-container {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 3;
  background-color: white !important;

  font-family: "Karla", sans-serif;
  letter-spacing: -1px;
}

.nav-link {
  /* font-family: "Source Sans Pro", sans-serif !important; */
}

.custom-navbar {
  /* background-color: #fafafa !important; */
  background-color: white !important;
  text-align: center;
}

.nav-logo {
  position: relative;
  width: 35px;
  margin-right: 25px;
  display: inline-block;
}

.navbar-nav {
  font-size: 1.4rem;
}

.nav-company-name {
  color: rgb(63, 63, 63);
  font-size: 1.8rem;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;

  /* position:  */
  display: inline-block;
  padding-left: 5vw;
  position: relative;
  top: 5px;
}

.smith {
  color: rgb(0, 0, 136);
  font-weight: 600;
}

.structural {
  font-size: 1rem;
  margin-left: 2px;
}

.nav-item-custom {
  /* letter-spacing: 1px; */
}

.navbar-light {
  /* box-shadow: none !important; */
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2);
}

@media only screen and (min-width: 600px) {
  .nav-logo {
    width: 50px;
  }
  .nav-company-name {
    padding-left: 0;
  }
}

.no-bs-border,
.no-bs-border:focus,
.no-bs-border:active {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  font-size: medium !important;
}

.dropdown-item {
  color: #e1e1e1 !important;
}

.dropdown-toggle {
  font-size: medium;
}

.dropdown-item:hover {
  background-color: #121212 !important;
}

.navbar-radius {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.navbar-collapse {
  position: relative;
  right: 0;
}

.custom-navbar-brand {
  /* color: #e1e1e1 !important; */
  color: white !important;
}

.custom-navbar-brand:hover {
  /* color: #9e9e9e !important; */
  color: white !important;
}

.navbar-nav {
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .nav-container {
    background-color: none !important;
  }
  .nav-company-name {
    /* top: 1.5vh; */
    /* margin-left: 3vh; */
    top: 0;
    font-size: 1.4rem;
    margin: 0;
    padding: 0;
  }

  .structural {
    font-size: 1.3rem;
  }

  .nav-item-custom {
    /* margin-left: 30px; */
    font-size: 1.2rem;
    letter-spacing: 0px;
    /* font-weight: bold; */
  }
}

@media only screen and (min-width: 1200px) {
  .nav-item-custom-active {
    border-bottom: 2px solid #000088;
  }

  .nav-company-name {
    top: 0.6vh;
    margin-left: 4vh;
    font-size: 2rem;
  }

  .nav-item-custom {
    margin-left: 25px;
    font-size: 1.3rem;
  }

  .structural {
    font-size: 1.3rem;
  }

  .navbar-nav {
    margin-top: 7px;
    margin-right: 40px;
  }
}

@media only screen and (min-width: 992px) {
  .nav-item-custom-active {
    border-bottom: 2px solid #000088;
  }
}
