.about-container {
  position: relative;
  background-color: #ffffff;
  text-align: center;
  width: 100%;
  font-family: "Karla", sans-serif !important;
  margin-top: 14vh;

  height: 100vh;
}

.about-header {
  font-weight: 600;
  font-size: 1.9rem;
}

.about-text {
  margin-top: 10vh;
  padding: 0px 25%;
  font-size: 1.2rem;
}

@media screen and (max-width: 600px) {
  .about-text {
    margin-top: 13vh;
    padding: 0px 40px;
    font-size: 1.2rem;
  }
}
