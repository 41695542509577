.home-container {
  position: relative;
  background-color: #ffffff;
  text-align: center;
  width: 100%;
}

.text-logo {
  position: relative !important;
  top: 25vh !important;
  width: 50%;
}

.ig-icon {
  font-size: 40pt;
  /* margin-top: 30px; */
  position: relative;
  top: 15px;
  left: 10px;
  color: black;
}

.ig-icon:hover {
  color: gray;
}

/* .home-bg {
  position: fixed;
  top: 8vh;
  left: 0;
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  margin-top: 25px;
  font-size: 21px;
} */

.btn-container {
  position: relative;
  width: 100%;
  top: 30vh;
  /* margin: 10px; */
}

.shop-btn {
  position: relative;
  margin: auto;
  background-color: black;
  color: black;
}

a.button2 {
  display: inline-block;
  padding: 0.5em 3em;
  border: 0.16em solid #000000;
  margin: 0 0.3em 0.3em 0;
  box-sizing: border-box;
  text-decoration: none;
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 0.5px;
  font-weight: 600;
  color: #000000;
  text-align: center;
  transition: all 1s;
}

a.button2:hover {
  color: #ffffff;
  border-color: #000000;
  background-color: #000000;
}
a.button2:active {
  color: #ffffff;
  border-color: #000000;
  background-color: #000000;
}
@media all and (max-width: 30em) {
   a.button2 {
    display: block;
    margin: 0.4em auto;
  }
}

@media screen and (max-width: 600px) {
  .text-logo {
    top: 30vh;
    width: 80%;
  }

  .btn-container {
    top: 35vh;
  }
}
