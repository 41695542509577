body {
  /* font-family: "Raleway", sans-serif; */

  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Helvetica Neue", Arial, sans-serif; */

  /* font-family: "Roboto", sans-serif; */
  /* font-family: "Merriweather", sans-serif; */
  /* font-family: "Montserrat", sans-serif; */
  /* font-family: "Roboto Condensed", sans-serif; */
  /* font-family: "Sawarabi Mincho", sans-serif; */

  /* font-family: "Rajdhani", sans-serif; */

  font-family: "Courier New", Courier, monospace;
  /* font-weight: 600; */
  background-color: #ffffff;
  color: #e1e1e1;
}

.text-logo {
  position: relative;
  left: 0;
}

.clothing-container {
  /* padding-top: 12vh; */
  margin-top: 12vh;
  transition: padding-right 0.25s;
  width: 100%;
  align-items: center;
  text-align: center;
}

.clothing-grid {
  margin-top: 5vh;
}

.clothing-title {
  /* text-align: left;
  font-size: x-large;
  font-weight: bold;
  margin-left: 5%; */

  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}

.load-more-btn {
  margin-bottom: 20px;
  margin-left: 15px;
  overflow: hidden;
  padding: 1;

  /* border-radius: 15px; */
  text-align: center;
  width: 100vw;
  margin-right: 2%;
  height: 55px;
  font-size: large;
}

.load-more-btn-2 {
  border: 2px solid black;
  color: black !important;
  font-weight: 500;
  margin-bottom: 20px;
  margin-left: 15px;
  overflow: hidden;
  padding-top: 10px;

  /* border-radius: 15px; */
  text-align: center;
  width: 100vw;
  margin-right: 2%;
  height: 55px;
  font-size: large;
}

.load-more-btn-2:hover {
  color: #ffffff !important;
  border-color: #000000;
  background-color: #000000;
}
.load-more-btn-2:active {
  color: #ffffff !important;
  border-color: #000000;
  background-color: #000000;
}
@media all and (max-width: 30em) {
   .load-more-btn-2 {
    display: block;
    margin: 0.4em auto;
  }
}

.row {
  align-items: center;
  /* align-items: left; */
  /* margin-top: 2%; */
}

.no-bs-border,
.no-bs-border:focus,
.no-bs-border:active {
  outline: none;
  border: none;
  box-shadow: none;
  font-size: medium;
}

.article-margin {
  padding-right: 1em;
}

.no-margin {
  padding-right: 1em;
}

/* Checkbox  */

/* The container */
.checkBox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkBox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkBox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkBox-container input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkBox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkBox-container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkBox-div {
  width: 100px;
  color: gray;
}

@media only screen and (max-width: 800px) {
  /* .article-margin {
    padding-right: 1em;
  }

  .no-margin {
    padding-right: 1em;
  }

  .news-container {
  } */

  .clothing-title {
    font-size: 1.9rem;
  }
}

.margin-top {
  margin-top: 4em;
}
